@media print {
    .global-navi-wrapper {display: none !important}

    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    :root {
        font-size: 10pt !important;
        background: none !important;
    }

    .core-wrapper {height: auto !important}

    table,
    tbody,
    tr {
        position: relative !important;
        break-inside: avoid !important;
    }
}
