@import '~styles/_mixins.scss';

@keyframes slideUp {
    0% {transform: translateY(0)}
    100% {transform: translateY(-100%)}
}

#cookie-disclaimer {
    position: fixed;
    top: 100%;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 1005;
    display: flex;
    align-items: center;
    padding: 1em 2em;
    border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    background: var(--mono-white);
    box-shadow: var(--box-shadow-m);
    animation: slideUp .2s .4s forwards;

    .cookie-disclaimer-body {margin-right: auto}

    button {
        width: auto;
        margin: 0 0 0 2rem;
    }

    .button--secondary {
        @include body-xxs();
        padding: 0;
        opacity: .3;
    }

    @media print {display: none !important}
}
