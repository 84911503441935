.core-wrapper {
    display: flex;
    flex-flow: row;
    height: 100vh;

    .core-content {
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
        scroll-behavior: smooth;
    }

    // Might bork something. needed only for FF because of
    // page-breaks not working on FF for flex elements.
    // See: https://www.jeffersonscher.com/ext/printable.html
    @media print {display: block !important}
}
