@keyframes nudgeLeft {
    0% {transform: translateX(0)}
    50% {transform: translateX(-.25em)}
    100% {transform: translateX(0)}
}

@keyframes nudgeUp {
    0% {transform: translateY(0)}
    50% {transform: translateY(-.25em)}
    100% {transform: translateY(0)}
}

@keyframes fadeIn {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(1.5em);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pill-text-appear {
    0% {flex: 0}
    100% {flex: 1}
}
