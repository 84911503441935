.global-navi-wrapper {
    position: relative;
    z-index: 6;
    flex: 0 0 var(--navigation-bar-width);
    display: flex;
    flex-flow: column nowrap;
    width: var(--navigation-bar-width);
    height: 100%;
    background-color: var(--mono-0-black);
    overflow-x: hidden;
    overflow-y: auto;

    h4 {color: var(--mono-white)}

    .nav-item {
        position: relative;
        flex: 0 1 5em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--navigation-bar-width);
        min-height: 3rem;
        color: transparent;

        &::before {
            width: max-content;
            max-width: calc(100% - 1rem);
            padding: 0 .2rem;
            word-break: break-word;
            text-align: center;
        }

        &:hover {
            &::before {top: 85%}
            &::after {background-color: var(--branding-light-grass)}
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 2.5em;
            height: 2.5em;
            transition: background .1s linear;
        }

        &.active {
            &::after {background: var(--branding-grass)}

            h4 {color: var(--mono-0-black)}

            .invert-dark {fill: var(--mono-white)}
            .invert-light {fill: var(--mono-0-black)}
        }

        svg {
            position: relative;
            z-index: 1;
            color: var(--mono-white);

            .invert-dark {fill: var(--mono-0-black)}
            .invert-light {fill: var(--mono-white)}
        }

        img {
            border: 0;
            border-radius: 50%;
            object-fit: cover;
        }

        .profile-picture {
            z-index: 1;
            display: flex;
            color: var(--fill-mono-00-true-black);
        }
    }

    a.nav-item:last-of-type {
        margin: auto 0 0;

        &:hover::before {
            top: auto;
            bottom: 80%;
        }
    }

    .logo {
        svg {
            display: block;
            margin: 0 auto;
        }
    }
}
