:root {
    --branding-bg-grey: #f0f0f0;
    --branding-dark-blue: #233c73;
    --branding-dark-grey: #999;
    --branding-grass: #12cc94;
    --branding-lake-green: #73c364;
    --branding-light-grass: #a4e5d9;
    --branding-light-green: #dff3f5;
    --branding-light-grey: #f9f9f9;
    --branding-light-yellow: #faeec5;
    --branding-middle-grey: #e5e5e5;
    --branding-mint: #69dcd7;
    --branding-table-category: #bebebe;

    --chart-color-matte-blue: #5596c8;
    --chart-color-matte-green: #3c9678;
    --chart-color-matte-orange: #dc734b;
    --chart-color-matte-purple: #7878c3;
    --chart-color-matte-red: #dc4b4b;
    --chart-color-matte-yellow: #facd55;

    --file-color-light-blue: #96cdff;
    --file-color-orange: #fad7af;
    --file-color-purple: #d2d2ff;
    --file-color-red: #ffe1d7;
    --file-color-sky-blue: #b9f0ff;
    --file-color-yellow: #ffe699;

    --fill-color-blue: #007eff;
    --fill-color-green: #5ae1cd;
    --fill-color-red: #fa645f;
    --fill-color-yellow: #ffc224;

    --fill-mono-00-true-black: #000;

    --mono-0-black: #262626;
    --mono-1-grey-dark-trans: rgba(0, 0, 0, .25);
    --mono-2-grey-dark-trans: rgba(0, 0, 0, .1);
    --mono-3-grey-dark-trans: rgba(0, 0, 0, .03);
    --mono-transparent: transparent; // Be mindful where you use this vs. just `transparent`
    --mono-white: #fff;

    --very-light-pink: #ffdfdf;


    --font-stack-primary: 'Open Sans Regular',
                          'OpenSans-Regular',
                          'Open Sans',
                          'OpenSans',
                           sans-serif;

    --box-shadow-m: 0 4px 8px rgba(0, 0, 0, .5);

    --border-radius-xxs: 2px;
    --border-radius-xs: 3px;
    --border-radius-s: 4px;
    --border-radius-m: 8px;

    --fleet-blue-translucent: rgba(#b9f0ff, .7);


    // UI 2 (DON'T USE THESE IN NEW CODE. REMOVE WHEN UNUSED)
    // Fill colors
    --fill-red: #f57170;

    // Chart colors
    --chart-teal: #03fcb6;

    // Shades of grey
    --grey-dark-40: rgba(0, 0, 0, .4);
    --grey-dark-7: rgba(0, 0, 0, .07);
    --dark-text-color: #102542;

    // Box shadows
    --box-shadow-s: 0 2px 4px rgba(0, 0, 0, .5);
    --box-shadow-l: 0 6px 10px 0 rgba(0, 0, 0, .5);

    // Other
    --navigation-bar-width: 5.95rem;
    --inner-nav-bar-width: 20em;
    --top-padding: 12rem;

    // UI 1 (DON'T USE THESE IN NEW CODE. REMOVE WHEN UNUSED)
    --light-text-color: rgba(16, 37, 66, .5);
    --lighter-text-color: rgba(16, 37, 77, .4);
    --box-shadow-primary: 0 4px 8px var(--mono-1-grey-dark-trans);

    // Label colors are defined with these old names. Can't be removed without changing all user's label colors.
    --theme-dark-accent: var(--mono-0-black);
    --brand-gray-mid: var(--branding-middle-grey);
    --file-purple: var(--file-color-purple);
    --file-blue: var(--file-color-light-blue);
    --file-sky-blue: var(--file-color-sky-blue);
    --file-orange: var(--file-color-orange);
    --file-yellow: var(--file-color-yellow);
    --file-red: var(--file-color-red);
}
