.icon-fleets {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    z-index: 1;

    &.invert {
        .invert-light {fill: var(--mono-0-black)}
        .invert-dark {fill: var(--mono-white)}
    }

    svg {
        font-size: .7em;
        flex: 0 0 50%;
        margin: .1em -.01em;
        width: 1em;
        height: 1em;

        &:first-child {flex: 0 0 100%}

        .invert-light {fill: var(--mono-white)}
        .invert-dark {fill: var(--mono-0-black)}
    }
}
