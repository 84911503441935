.data-loading-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .5);
    z-index: 999;
    animation: fadeIn 2s;
}
