// From designs "Text styles" page

@mixin h1() {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.08333;
}
@mixin h2() {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.125;
}
@mixin h3() {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.16667;
}
@mixin h4($weight: 600) {
    font-size: 1.25rem;
    font-weight: $weight;
    line-height: 1.2;
}
@mixin h5() {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin body-m() { // In designs, the text style Paragraph === Body M
    font-size: 1rem;
    line-height: 1.25;
    font-weight: normal;
}
@mixin body-s() {
    font-size: .875rem;
    line-height: 1.28571;
    font-weight: normal;
}
@mixin body-xs() {
    font-size: .75rem;
    font-weight: normal;
    line-height: 1.33333;
}
@mixin body-xxs() {
    font-size: .625rem;
    font-weight: normal;
    line-height: 1.6;
}
@mixin label-m() {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.28571;
}
@mixin label-s() {
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin label-xs() {
    font-size: .625rem;
    font-weight: 600;
    line-height: 1.8;
}
@mixin label-xxs() {
    font-size: .5rem;
    font-weight: 600;
    line-height: 2.25;
}
@mixin button-text() {
    color: var(--dark-text-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
}
@mixin button-primary() {
    @include button-text();
    display: block;
    background: var(--branding-grass);
    border: 1px solid var(--mono-2-grey-dark-trans);
    text-align: center;
    user-select: none;
    padding: calc(.625em - 1px) 1em;
    min-width: max-content;
    width: 25rem;
    max-width: calc(100% - 3rem);
}
@mixin button-secondary() {
    @include button-text();
    display: block;
    background: transparent;
    text-align: center;
    user-select: none;
    padding: calc(.625em - 1px) 1em;
    min-width: max-content;
    max-width: calc(100% - 3rem);
}
@mixin price() {
    color: var(--fill-color-blue);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.16667;
}

// Other / Misc.
@mixin text-input-field() {
    @include body-m();
    appearance: none;
    font-family: inherit;
    line-height: initial; // Prevents cutoff text
    color: inherit;
    border-radius: var(--border-radius-s);
    background: var(--mono-white);
    width: 100%;
    border: 1px solid transparent;
    padding: calc(.75em - 1px) 1em;
    transition: border .2s,
                box-shadow .2s;

    &:focus {
        box-shadow: var(--box-shadow-primary);
        outline: none;
    }

    &:disabled {opacity: .7}
    &::placeholder {color: var(--light-text-color)}
}
@mixin visually-hidden() {
    position: absolute;
    z-index: -1;
    opacity: 0;
    clip-path: circle(.1px);
    width: 1px;
    height: 1px
}
@mixin tag() {
    @include label-m();
    display: inline-block;
    background: var(--branding-grass);
    border-radius: .75rem;
    padding: .2em 1em;
}
@mixin tooltip-top-hover() {
    bottom: 105%;
    opacity: 1;
    color: var(--dark-text-color);
}
@mixin add-button() {
    display: block;
    background: var(--branding-grass);
    border-radius: 50%;
    height: 5.5rem;
    width: 5.5rem;
}
