@charset "utf-8";

@import '_variables.scss';
@import '_mixins.scss';
@import 'keyframes.scss';

::selection {background: var(--file-color-light-blue)}
img::selection {background: var(--fleet-blue-translucent)}

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    background: var(--mono-2-grey-dark-trans);
    border-radius: 1rem;

    &-thumb {
        background: var(--mono-1-grey-dark-trans);
        border-radius: 1rem;
    }
}

.thick-scrollbar {
    &::-webkit-scrollbar {
        width: 1rem;
        height: 1rem;
    }

    &.left-not-rounded::-webkit-scrollbar {border-radius: 0 .5rem .5rem 0}
}

.narrow-scrollbar {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
    }

    &.left-not-rounded::-webkit-scrollbar {border-radius: 0 .25rem .25rem 0}
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: var(--mono-1-grey-dark-trans) var(--mono-2-grey-dark-trans)
}

:root {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1.25;
    background: var(--branding-bg-grey);
    scroll-behavior: smooth;
    color: var(--dark-text-color);
    font-family: var(--font-stack-primary);
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background: inherit;
}

h1,
body .h1 {
    @include h1();
    margin: 0;
}

h2,
body .h2 {
    @include h2();
    margin: 0;
}

h3,
body .h3 {
    @include h3();
    margin: 0;
}

h4,
body .h4 {
    @include h4();
    margin: 0;
}

h5,
body .h5 {
    @include h5();
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color .2s;

    &:hover {
        text-decoration: none;
        color: var(--light-text-color);
    }
}

label {
    display: inline;
    margin: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

address {margin: 0}
table {border-spacing: 0}
dl {margin: 0}
dd {margin: 0}

fieldset {
    display: block;
    display: contents;
    border: 0;
    padding: 0;
    margin: 0;
}

button,
[type='button'],
[type='submit'],
[type='reset'],
.button {
    @include button-text();
    appearance: none;
    align-items: center;
    font-size: 1em;
    font-family: inherit;
    padding: 0;
    border: 0;
    margin: 0 auto;
    border-radius: 1.5em;
    background: none;
    line-height: inherit;
    color: inherit;
    user-select: none;
    transition: color .2s,
                filter .2s,
                opacity .2s;

    &:not(:disabled) {cursor: pointer}

    &:not(:disabled):hover {
        text-decoration: none;
        filter: brightness(1.1);
        color: var(--light-text-color);
    }

    &:not(:disabled):focus {outline: none;}
    &:not(:disabled):active {filter: brightness(.9)}

    &[disabled],
    &.disabled,
    &:disabled {
        cursor: default;
        filter: saturate(.2)
                contrast(.5)
                brightness(1.5);

        * {pointer-events: none !important} /* stylelint-disable-line */
    }
}

body .button { // Higher specificity to override input[*] styles
    @include button-text();
    align-items: center;
    display: inline-block;
    background: none;
    border: 0;
    padding: 0;
    border-radius: 1.5em;
    text-align: center;
    cursor: pointer;

    &:not(:disabled) {cursor: pointer}

    &:not(:disabled):hover {
        text-decoration: none;
        filter: brightness(1.1);
        color: var(--light-text-color);
    }

    &:not(:disabled):focus {outline: none;}
    &:not(:disabled):active {filter: brightness(.9)}

    &--primary {@include button-primary()}
    &--secondary {@include button-secondary()}

    &--link {
        @include button-text();
        background: none;
        border: 0;
        text-decoration: underline;
        transition: color .2s;
    }

    &.disabled {
        cursor: default;
        filter: saturate(.2)
                contrast(.5)
                brightness(1.5);

        * {pointer-events: none !important} /* stylelint-disable-line */
    }
}

[type='text'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'] {@include text-input-field()}

select {
    @include body-m();
    font-family: inherit;
    font-size: 1em;
    color: inherit;
    border-radius: var(--border-radius-s);
    background: var(--mono-white);
    width: 100%;
    border: 1px solid transparent;
    padding: calc(.75em - 1px) 3em calc(.75em - 1px) 1em;
    cursor: pointer;
    transition: border .2s,
                box-shadow .2s;

    &:focus {
        box-shadow: var(--box-shadow-primary);
        outline: none;
    }
}

#root {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
}

.tag {@include tag()}

.bold {font-weight: bold}

[data-tooltip] {
    position: relative;

    // Fix tooltip z-index. Maybe rethink button filters?
    &:not([disabled]):hover {filter: none !important;} /* stylelint-disable-line */

    &:disabled::before {display: none;}

    &::before {
        @include body-xxs();
        content: attr(data-tooltip);
        opacity: 0;
        position: absolute;
        top: 95%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        padding: .5rem;
        box-shadow: var(--box-shadow-s);
        border-radius: var(--border-radius-xs);
        background: var(--mono-white);
        color: transparent;
        pointer-events: none;
        text-align: left;
        white-space: pre-wrap;
        transition: all .2s,
                    color .2s;
    }

    &:hover::before {
        top: 105%;
        opacity: 1;
        color: var(--dark-text-color);
    }

    &.tooltip-right {
        &::before {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 95%;
            transform: translateY(-50%);
        }

        &:hover::before {
            left: 105%;
            bottom: auto;
        }
    }

    &.tooltip-left {
        &::before {
            top: 50%;
            right: 95%;
            bottom: auto;
            left: auto;
            transform: translateY(-50%);
        }

        &:hover::before {
            right: 105%;
            bottom: auto;
        }
    }

    &.tooltip-top {
        &::before {
            top: auto;
            right: auto;
            bottom: 95%;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover::before {bottom: 105%}
    }
}

svg {
    opacity: 1;

    text {cursor: text}

    .invert-dark {fill: var(--mono-white)}
    .invert-light {fill: var(--mono-0-black)}
}

.react-datepicker {
    display: flex !important;
    flex-flow: row nowrap;

    &-popper {z-index: 5 !important}

    &__time-container {
        display: flex;
        flex-flow: column nowrap;
    }

    &__time {
        flex: 1 1 1px;
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
    }

    &__time-box {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;
    }

    &__time-list {
        flex: 1 1 auto;
        height: auto !important;
    }
}
